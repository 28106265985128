<template>
  <b-sidebar
    id="opened-modal-form"
    :visible="openedModalForm"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:opened-modal-form', val)"
  >
    <template v-if="item.id === undefined">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('general.create') }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <b-form class="p-2">
        <b-form-group
          :label="'Init GPT generation'"
          label-for="search_gpt_status"
        >
          <b-form-checkbox
            id="search_gpt_status"
            v-model="newData.gpt"
            :state="errors && errors.gpt ? false : null"
          />
          <b-form-invalid-feedback v-if="errors && errors.gpt">
            {{ errors.gpt[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
        <template v-if="newData.gpt">
          <b-form-group
            :label="$t('form.search.relation')"
            label-for="relation"
          >
            <b-form-input
              id="relation"
              v-model="item.relation"
              :state="errors && errors.relation ? false : null"
            />
            <b-form-invalid-feedback v-if="errors && errors.relation">
              {{ errors.relation[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            :label="$t('form.quantity.label')"
            label-for="query"
          >
            <b-form-input
              id="qnt"
              v-model="newData.qnt"
              :state="errors && errors.qnt ? false : null"
            />
            <b-form-invalid-feedback v-if="errors && errors.qnt">
              {{ errors.qnt[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            :label="`Comment for GPT (optional)`"
            label-for="comment"
          >
            <b-form-input
              id="comment"
              v-model="newData.comment"
              :state="errors && errors.comment ? false : null"
            />
            <b-form-invalid-feedback v-if="errors && errors.comment">
              {{ errors.comment[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </template>
        <template v-else>
          <b-form-group
            :label="$t('form.search.query')"
            label-for="query"
          >
            <b-form-input
              id="query"
              v-model="item.query"
              :state="errors && errors.query ? false : null"
            />
            <b-form-invalid-feedback v-if="errors && errors.query">
              {{ errors.query[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            :label="$t('form.search.relation')"
            label-for="relation"
          >
            <b-form-input
              id="relation"
              v-model="item.relation"
              :state="errors && errors.relation ? false : null"
            />
            <b-form-invalid-feedback v-if="errors && errors.relation">
              {{ errors.relation[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </template>
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </div>
      </b-form>
    </template>
    <template v-else>
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('general.edit') }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <b-form class="p-2">
        <b-form-group
          :label="$t('form.search.query')"
          label-for="query"
        >
          <b-form-input
            id="query"
            v-model="item.query"
            :state="errors && errors.query ? false : null"
          />
          <b-form-invalid-feedback v-if="errors && errors.query">
            {{ errors.query[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          :label="$t('form.search.relation')"
          label-for="relation"
        >
          <b-form-input
            id="relation"
            v-model="item.relation"
            :state="errors && errors.relation ? false : null"
          />
          <b-form-invalid-feedback v-if="errors && errors.relation">
            {{ errors.relation[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { mapGetters } from 'vuex'
import StatusMixin from '@/mixins/StatusMixin'

export default {
  components: {
    BFormCheckbox,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
  },
  directives: {
    Ripple,
  },
  mixins: [StatusMixin],
  model: {
    prop: 'openedModalForm',
    event: 'update:opened-modal-form',
  },
  props: {
    openedModalForm: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newData: {
        gpt: false,
        qnt: 5,
        comment: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    hide() {
      this.$store.dispatch('validation/clearErrors')
      this.$emit('update:opened-modal-form', false)
    },
    onSubmit() {
      if (this.item.id === undefined) {
        this.$http.post('/api/search/relations', { ...this.item, ...this.newData })
          .then(() => {
            this.$emit('refetch-data')
            this.$emit('update:opened-modal-form', false)
            this.$store.dispatch('validation/clearErrors')
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      } else {
        this.$http.put(`/api/search/relations/${this.item.id}`, this.item)
          .then(() => {
            this.$emit('refetch-data')
            this.$emit('update:opened-modal-form', false)
            this.$store.dispatch('validation/clearErrors')
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#opened-modal-form {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
